var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sb-page-header',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('h1',[_vm._v("Woordenboek beheer")])]},proxy:true}])}),_c('sb-call-out',{staticStyle:{"max-width":"800px"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v("ℹ️")]},proxy:true},{key:"right",fn:function(){return [_c('p',[_vm._v("Je kan hier een woordenboek-beheerder account aanmaken.")]),_c('p',[_vm._v(" Een woordenboek-beheerder heeft slechts toegang tot het woordenboek, en gerelateerde features om het woordenboek te beheren. Dit betreft het inzien en wijzigen van woorden, definities en relaties. ")])]},proxy:true}])}),_c('div',{staticStyle:{"position":"relative"}},[(_vm.isLoading)?_c('sb-loading'):_vm._e(),(!_vm.isCreated)?[_c('sb-divider',{attrs:{"title":"Woordenboek-beheerder aanmaken"}}),_c('i-form',{ref:"userForm",staticClass:"row-4",attrs:{"model":_vm.formData,"autocomplete":"off"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"autocomplete":"false","name":"hidden","type":"text"}}),_c('form-item',{attrs:{"label":"Voornaam","prop":"firstName","rules":{
            required: true,
            message: 'Voer een voornaam in',
            trigger: 'blur',
          }}},[_c('i-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.formData.firstName),callback:function ($$v) {_vm.$set(_vm.formData, "firstName", $$v)},expression:"formData.firstName"}})],1),_c('form-item',{attrs:{"label":"Tussenvoegsel","prop":"prefix"}},[_c('i-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.formData.prefix),callback:function ($$v) {_vm.$set(_vm.formData, "prefix", $$v)},expression:"formData.prefix"}})],1),_c('form-item',{attrs:{"label":"Achternaam","prop":"lastName","rules":{
            required: true,
            message: 'Voer een achternaam in',
            trigger: 'blur',
          }}},[_c('i-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.formData.lastName),callback:function ($$v) {_vm.$set(_vm.formData, "lastName", $$v)},expression:"formData.lastName"}})],1),_c('form-item',{attrs:{"label":"E-mailadres","prop":"email","rules":{
            required: true,
            message: 'Voer een e-mailadres in',
            trigger: 'blur',
          }}},[_c('i-input',{attrs:{"type":"email","name":"dictionary-manager-email","autocomplete":"off"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_c('form-item',{attrs:{"label":"Wachtwoord","prop":"password","rules":{
            trigger: 'blur',
            validator: _vm.passwordValidator,
          }}},[_c('i-input',{attrs:{"type":"password","autocomplete":"new-password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})],1),_c('form-item',{attrs:{"label":"Wachtwoord bevestigen","prop":"passwordConfirm","rules":{
            trigger: 'blur',
            validator: _vm.passwordConfirmValidator,
          }}},[_c('i-input',{attrs:{"type":"password","autocomplete":"new-password"},model:{value:(_vm.formData.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.formData, "passwordConfirm", $$v)},expression:"formData.passwordConfirm"}})],1),_c('div',{staticClass:"flex flex-justify-end",staticStyle:{"max-width":"600px","padding":"0 1.5rem"}},[_c('i-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("Opslaan")])],1)],1)]:_vm._e(),(_vm.isCreated)?_c('div',{staticClass:"row-4"},[_c('p',[_vm._v("Nieuwe woordenboek-beheerder aangemaakt!")]),_c('p',[_vm._v(" De beheerder kan inloggen met het ingevoerde e-mailadres en wachtwoord: ")]),_c('div',{staticClass:"sb_dictionary-maintainers_credentials"},[_c('div',{staticClass:"flex flex-align-center gap-3"},[_c('div',{staticClass:"sb_dictionary-maintainers_credentials_label"},[_vm._v(" Gebruikersnaam ")]),_c('pre',[_vm._v(_vm._s(_vm.username))])])]),_c('div',{staticClass:"sb_dictionary-maintainers_credentials"},[_c('div',{staticClass:"flex flex-align-center gap-3"},[_c('div',{staticClass:"sb_dictionary-maintainers_credentials_label"},[_vm._v(" Wachtwoord ")]),(_vm.doShowCredentials)?_c('pre',[_vm._v(_vm._s(_vm.formData.password))]):_vm._e(),_c('i-button',{on:{"click":function($event){_vm.doShowCredentials = !_vm.doShowCredentials}}},[_vm._v(" "+_vm._s(_vm.doShowCredentials ? 'verbergen' : 'wachtwoord weergeven')+" ")])],1)])]):_vm._e()],2),_c('sb-spacer',{attrs:{"height":"200"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }