var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i-form',_vm._b({ref:"form",staticClass:"row-4",attrs:{"model":_vm.formData,"label-width":80},nativeOn:{"submit":function($event){$event.preventDefault();_vm.manualSubmission ? _vm.$emit('manual-submit', _vm.self) : _vm.submit()}}},'i-form',_vm.$attrs,false),[_c('form-item',{attrs:{"label":_vm.label,"prop":"word","rules":{
        required: true,
        trigger: 'blur',
        validator: _vm.validator,
      }}},[_c('sb-info',[_c('p',[_vm._v("Het woord dat je wil definiëren.")]),_c('p',[_vm._v("Bijvoorbeeld "),_c('i',[_vm._v("\"blad\", \"blaadje\" of \"bladeren\"")])])]),_c('i-input',{attrs:{"placeholder":_vm.label,"required":"","disabled":!!_vm.createdEntry},model:{value:(_vm.formData.word),callback:function ($$v) {_vm.$set(_vm.formData, "word", $$v)},expression:"formData.word"}},[(!_vm.createdEntry)?_vm._t("default",function(){return [_c('i-button',{on:{"click":_vm.submit}},[_vm._v(" Opslaan ")])]},{"slot":"append"}):_vm._e()],2)],1)],1),(_vm.withDefinitions && _vm.createdEntry)?_c('div',{staticClass:"sb_word-entry-form_definitions"},[_c('sb-divider',{attrs:{"title":`Definities (${
        _vm.get(_vm.getWordEntryById, 'definitions', 'length') || 0
      })`,"top-space":"0"}}),_c('div',[_vm._l((_vm.get(_vm.getWordEntryById, 'definitions')),function(definition){return _c('div',{key:definition.id,staticClass:"sb_dictionary-form"},[_c('sb-word-definition-form',{attrs:{"definition-id":definition.id,"default-data":_vm.objectPick(
              definition,
              'image',
              'audio',
              'pos',
              'content',
              'examples',
            ),"word":_vm.createdEntry.word,"word-id":_vm.createdEntry.id,"default-mode":"readonly"},on:{"delete":_vm.handleDefinitionDelete}})],1)}),(
          !!_vm.getWordEntryById &&
          !_vm.get(_vm.getWordEntryById, 'definitions', 'length')
        )?_c('p',[_c('i',[_vm._v("Nog geen definities toegevoegd")])]):_vm._e(),(_vm.showNewDefinitionForm)?_c('sb-word-definition-form',{staticClass:"row-4",attrs:{"word":_vm.createdEntry.word,"default-mode":"edit"},on:{"cancel":function($event){_vm.showNewDefinitionForm = false},"created":_vm.handleDefinitionCreated}}):_c('i-button',{staticClass:"row-2",on:{"click":function($event){_vm.showNewDefinitionForm = true}}},[_vm._v(" Definitie toevoegen ")])],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }