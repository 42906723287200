var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mode === 'edit')?_c('i-form',_vm._b({ref:"form",staticClass:"sb_word-definition-input",attrs:{"model":_vm.formData,"label-width":140}},'i-form',_vm.$attrs,false),[_c('form-item',{attrs:{"label":"Afbeelding"}},[_c('sb-info',[_c('p',[_vm._v(" Voeg een afbeelding toe die relevant is voor deze definitie van het woord. ")]),_c('p',[_c('i',[_vm._v("jpeg of png, maximaal 5MB")])])]),_c('upload',{attrs:{"action":_vm.uploadUrl,"default-file-list":_vm.getDefaultFileList('image'),"headers":{ Authorization: _vm.Authorization },"max-size":1024 * 5,"on-remove":() => (_vm.formData.image = undefined),"on-success":(event) => (_vm.formData.image = event),"accept":".jpg, .jpeg, .png","type":"drag"}},[_vm._v(" Klik, of sleep een afbeelding ")])],1),_c('form-item',{attrs:{"label":"Audio"}},[_c('sb-info',[_c('p',[_vm._v("Voeg een audiobestand toe met de uitspraak van het woord.")]),_c('p',[_c('i',[_vm._v("mpeg, maximaal 5MB")])])]),_c('upload',{attrs:{"action":_vm.uploadUrl,"default-file-list":_vm.getDefaultFileList('audio'),"headers":{ Authorization: _vm.Authorization },"max-size":1024 * 5,"on-remove":() => (_vm.formData.audio = undefined),"on-success":(event) => (_vm.formData.audio = event),"accept":"audio/mpeg","type":"drag"}},[_vm._v(" Klik, of sleep een audiobestand ")])],1),_c('form-item',{attrs:{"label":"Woordsoort","prop":"pos","rules":{
      required: true,
      message: 'Kies een woordsoort',
      trigger: 'blur',
    }}},[_c('sb-info',[_c('p',[_vm._v(" Bijvoorbeeld: "),_c('i',[_vm._v(" Werkwoord, zelfstandig naamwoord, bijvoeglijk naamwoord, voornaamwoord, bijwoord, lidwoord, telwoord, voegwoord, voorzetsel of tussenwerpsel. ")])])]),_c('i-select',{attrs:{"clearable":""},model:{value:(_vm.formData.pos),callback:function ($$v) {_vm.$set(_vm.formData, "pos", $$v)},expression:"formData.pos"}},_vm._l((_vm.DEFAULT_POS),function(option){return _c('i-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),1)],1),_c('form-item',{attrs:{"label":"Betekenis","prop":"content","rules":{
      required: true,
      message: 'Vul een betekenis in',
      trigger: 'blur',
    }}},[_c('sb-info',[_c('p',[_vm._v("De betekenis van woord dat je wil definiëren.")]),_c('p',[_c('i',[_vm._v(" \"Plat, groen onderdeel van planten dat groeit aan een tak\" ")])])]),_c('i-input',{attrs:{"type":"textarea","placeholder":"betekenis"},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1),_c('form-item',{attrs:{"label":"Voorbeeldzinnen","prop":"examples"}},[_c('sb-info',[_c('p',[_vm._v(" Één of meerdere voorbeeldzinnen waar het woord in context wordt gebruikt. ")]),_c('p',[_c('i',[_vm._v(" \"Mag ik nog een blaadje van je, ik heb niks meer om op te schrijven.\" ")])])]),_c('div',{staticClass:"sb_dictionary-form_array-entry-grid"},[_vm._l((_vm.formData.examples),function(_,exampleIndex){return [_c('i-input',{key:`example-${exampleIndex}`,attrs:{"placeholder":"Voorbeeldzin"},model:{value:(_vm.formData.examples[exampleIndex]),callback:function ($$v) {_vm.$set(_vm.formData.examples, exampleIndex, $$v)},expression:"formData.examples[exampleIndex]"}}),_c('button',{key:`example-${exampleIndex}-remove`,attrs:{"type":"button"},on:{"click":function($event){return _vm.formData.examples.splice(exampleIndex, 1)}}},[_c('sb-button',{attrs:{"button-type":"light-black","size":"small","round":"","ghost":""}},[_c('sb-icon',{attrs:{"icon-id":"icon_minus"}})],1)],1)]}),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.formData.examples.push('')}}},[_c('sb-button',{attrs:{"size":"small","button-type":"light"}},[_c('sb-icon',{attrs:{"icon-id":"icon_plus"}}),_vm._v(" Voorbeeldzin ")],1)],1)],2)],1),_c('div',{staticClass:"flex flex-justify-end gap-2"},[_c('i-button',{on:{"click":function($event){_vm.resetFormData();
        _vm.mode = 'readonly';
        _vm.$emit('cancel');}}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]),_c('i-button',{on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.submitText)+" ")])],1)],1):_c('div',[_c('p',{staticStyle:{"font-size":"1.1rem"}},[_vm._v(" "+_vm._s(_vm.word)),(_vm.formData.pos)?_c('span',[_vm._v(":")]):_vm._e(),(_vm.formData.pos)?_c('i',[_vm._v(_vm._s(` ${_vm.formData.pos}`))]):_vm._e()]),(_vm.formData.image)?_c('img',{staticClass:"inline-block sb_word-definition-form_image",attrs:{"src":_vm.formData.image}}):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.formData.content)+" ")]),(_vm.formData.audio)?_c('audio',{staticClass:"block row-2",attrs:{"src":_vm.formData.audio,"controls":""}}):_vm._e(),_vm._l((_vm.formData.examples),function(example,index){return _c('p',{key:index},[_vm._v(" \""+_vm._s(example)+"\" ")])}),(!!_vm.definitionId)?_c('div',{staticClass:"flex flex-justify-end gap-2"},[_c('i-button',{attrs:{"type":"error"},on:{"click":_vm.remove}},[_vm._v(" "+_vm._s(_vm.deleteText)+" ")]),_c('i-button',{on:{"click":function($event){_vm.mode = 'edit'}}},[_vm._v(_vm._s(_vm.editText))])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }