var render = function render(){var _vm=this,_c=_vm._self._c;return _c('i-form',{ref:"form",staticClass:"row-4",attrs:{"label-width":80}},[_c('form-item',{staticStyle:{"max-width":"800px"},attrs:{"label":"Huidig"}},[_c('sb-info',[_c('p',[_vm._v("Gerelateerde woorden van \""+_vm._s(_vm.lemmaWord)+"\".")])]),_c('div',{staticClass:"sb_word-relations-form_current"},[_vm._l((_vm.get(_vm.getWordEntryById, 'relations')),function(relation,index){return [_c('router-link',{key:['match', index, relation.wordId, relation.match].join('-'),attrs:{"to":{
            name: _vm.$route.name.split('Dictionary')[0] + 'DictionaryEdit',
            params: {
              wordId: _vm.get(_vm.findWordByMatch(relation.match), 'id'),
            },
          }}},[_c('b',[_vm._v(" "+_vm._s(relation.match)+" ")])]),(_vm.get(_vm.editing, 'match') !== relation.match)?_c('i',{key:['relation', index, relation.wordId, relation.match].join('-')},[_vm._v(" "+_vm._s(_vm.getWordRelationsLabel(relation.relation))+" ")]):_c('sb-word-relation-select',{key:['edit-select', index, relation.wordId, relation.match].join('-'),staticStyle:{"width":"200px"},model:{value:(_vm.editing.relation),callback:function ($$v) {_vm.$set(_vm.editing, "relation", $$v)},expression:"editing.relation"}}),(_vm.get(_vm.editing, 'match') !== relation.match)?[_c('button',{key:['edit', index, relation.wordId, relation.match].join('-'),attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleEdit(relation)}}},[_c('sb-button',{attrs:{"button-type":"light-black","size":"small","round":"","ghost":""}},[_c('sb-icon',{attrs:{"icon-id":"icon_pencil"}})],1)],1),_c('button',{key:['remove', index, relation.wordId, relation.match].join('-'),attrs:{"type":"button"},on:{"click":function($event){return _vm.remove(relation, true)}}},[_c('sb-button',{attrs:{"button-type":"light-black","size":"small","round":"","ghost":""}},[_c('sb-icon',{attrs:{"icon-id":"icon_minus"}})],1)],1)]:[_c('i-button',{key:['edit-save', index, relation.wordId, relation.match].join('-'),attrs:{"type":"primary"},on:{"click":_vm.saveEdit}},[_vm._v(" Opslaan ")]),_c('i-button',{key:['edit-cancel', index, relation.wordId, relation.match].join(
                '-',
              ),on:{"click":_vm.toggleEdit}},[_vm._v(" Annuleren ")])]]})],2)],1),_c('form-item',{staticStyle:{"max-width":"800px"},attrs:{"label":"Nieuw","prop":"relations","rules":{}}},[_c('sb-info',[_c('p',[_vm._v("Definieer woorden die gerelateerd zijn aan \""+_vm._s(_vm.lemmaWord)+"\".")]),_c('p',[_vm._v("Bijvoorbeeld "),_c('i',[_vm._v("meervoud, enkelvoud")]),_vm._v(" of "),_c('i',[_vm._v("vervoeging")])])]),_vm._l((_vm.entries),function(entry,index){return _c('div',{key:index,staticClass:"sb_word-relations-form_entry"},[_c('div',{staticClass:"flex flex-col gap-2"},[_c('div',{staticClass:"flex flex-align-center gap-2"},[_c('b',[_vm._v(" "+_vm._s(_vm.lemmaWord)+" ")]),_vm._v(" is "),_c('sb-word-relation-select',{staticStyle:{"flex":"1"},model:{value:(entry.lemmaType),callback:function ($$v) {_vm.$set(entry, "lemmaType", $$v)},expression:"entry.lemmaType"}}),_vm._v(" van "),_c('sb-word-entry-select',{staticStyle:{"flex":"1"},model:{value:(entry.relationWord),callback:function ($$v) {_vm.$set(entry, "relationWord", $$v)},expression:"entry.relationWord"}})],1),(!!entry.relationWord && !!entry.lemmaType)?_c('div',{staticClass:"flex flex-align-center gap-2"},[_c('b',[_vm._v(_vm._s(entry.relationWord))]),_vm._v(" is "),_c('sb-word-relation-select',{staticStyle:{"width":"200px"},attrs:{"omit":[entry.lemmaType]},model:{value:(entry.relationType),callback:function ($$v) {_vm.$set(entry, "relationType", $$v)},expression:"entry.relationType"}}),_vm._v(" van "),_c('b',[_vm._v(_vm._s(_vm.lemmaWord))])],1):_vm._e()]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.entries.splice(index, 1)}}},[_c('sb-button',{attrs:{"button-type":"light-black","size":"small","round":"","ghost":""}},[_c('sb-icon',{attrs:{"icon-id":"icon_minus"}})],1)],1)])}),_c('section',{staticClass:"flex flex-space-between"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.entries.push({
            relationWord: undefined,
            lemmaType: undefined,
            relationType: undefined,
          })}}},[_c('sb-button',{attrs:{"size":"small","button-type":"light"}},[_c('sb-icon',{attrs:{"icon-id":"icon_plus"}}),_vm._v(" Relatie ")],1)],1),(!!_vm.entries.length)?_c('button',{attrs:{"type":"button"},on:{"click":_vm.create}},[_c('sb-button',{attrs:{"size":"small","disabled":!_vm.enableSubmitButton,"button-type":"primary"}},[_vm._v(" Opslaan ")])],1):_vm._e()])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }